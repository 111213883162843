import React, { useEffect, useState } from "react";
import { 
  Box, 
  Typography, 
  Card, 
  CardContent,
  Collapse,
  LinearProgress
} from "@mui/material";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_URL;

const HierarchicalView = ({ asoyId }) => {
  const [asoyHierarchy, setAsoyHierarchy] = useState(null);
  const [expandedStructural, setExpandedStructural] = useState({});
  const [expandedElement, setExpandedElement] = useState({});
  const [expandedComponent, setExpandedComponent] = useState({});

  useEffect(() => {
    const fetchAsoyHierarchy = async () => {
      try {
        const response = await axios.get(`${backendUrl}/asoy/${asoyId}/hierarchy/`);
        setAsoyHierarchy(response.data);
      } catch (error) {
        console.error("Error fetching hierarchy:", error);
      }
    };

    if (asoyId) {
      fetchAsoyHierarchy();
    }
  }, [asoyId]);

  const handleExpand = (setter, id) => {
    setter(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const calculateTimeline = (lastMaint, interval, lifespan) => {
    if (!lastMaint || !interval || !lifespan) return { percentage: 0, barColor: "gray" };

    const lastMaintenanceDate = new Date(lastMaint);
    const currentDate = new Date();
    const yearsSinceLastMaintenance = (currentDate - lastMaintenanceDate) / (1000 * 60 * 60 * 24 * 365.25);
    const percentage = (yearsSinceLastMaintenance / lifespan) * 100;

    let barColor = "green";
    if (percentage > 65 && percentage <= 90) barColor = "yellow";
    if (percentage > 90) barColor = "red";

    return { percentage: Math.min(Math.max(percentage, 0), 100), barColor };
  };

  if (!asoyId || !asoyHierarchy) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ mt: 2 }}>
      {asoyHierarchy.structural_elements.map((structuralElement) => {
        const { percentage: sePercentage, barColor: seColor } = calculateTimeline(
          structuralElement.last_maint,
          structuralElement.maint_interval,
          structuralElement.lifespan
        );

        return (
          <Card
            key={structuralElement.id}
            sx={{ mb: 2, backgroundColor: "primary.light", cursor: "pointer" }}
            onClick={() => handleExpand(setExpandedStructural, structuralElement.id)}
          >
            <CardContent>
              <Typography variant="h5">{structuralElement.name}</Typography>

              {/* Timeline for Structural Element */}
              <Box sx={{ mt: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={sePercentage}
                      sx={{
                        height: 10,
                        borderRadius: 5,
                        "& .MuiLinearProgress-bar": { backgroundColor: seColor },
                        backgroundColor: "#e0e0e0",
                      }}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="textSecondary">
                      {`${Math.round(sePercentage)}%`}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  Viimeisin huolto: {structuralElement.last_maint || "Ei tietoa"}
                </Typography>
              </Box>

              <Collapse in={expandedStructural[structuralElement.id]} timeout="auto" unmountOnExit>
                <Box sx={{ ml: 2, mt: 2 }}>
                  {structuralElement.elements.map((element) => {
                    const { percentage: elPercentage, barColor: elColor } = calculateTimeline(
                      element.last_maint,
                      element.maint_interval,
                      element.lifespan
                    );

                    return (
                      <Card
                        key={element.id}
                        sx={{ mb: 2, backgroundColor: "primary.main", cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleExpand(setExpandedElement, element.id);
                        }}
                      >
                        <CardContent>
                          <Typography variant="h6">{element.name}</Typography>

                          {/* Timeline for Element */}
                          <Box sx={{ mt: 1 }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box sx={{ width: "100%", mr: 1 }}>
                                <LinearProgress
                                  variant="determinate"
                                  value={elPercentage}
                                  sx={{
                                    height: 10,
                                    borderRadius: 5,
                                    "& .MuiLinearProgress-bar": { backgroundColor: elColor },
                                    backgroundColor: "#e0e0e0",
                                  }}
                                />
                              </Box>
                              <Box sx={{ minWidth: 35 }}>
                                <Typography variant="body2" color="textSecondary">
                                  {`${Math.round(elPercentage)}%`}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography variant="body2" color="textSecondary">
                              Viimeisin huolto: {element.last_maint || "Ei tietoa"}
                            </Typography>
                          </Box>

                          <Collapse in={expandedElement[element.id]} timeout="auto" unmountOnExit>
                            <Box sx={{ ml: 2, mt: 2 }}>
                              {element.components.map((component) => {
                                const { percentage: compPercentage, barColor: compColor } = calculateTimeline(
                                  component.last_maint,
                                  component.maint_interval,
                                  component.lifespan
                                );

                                return (
                                  <Card
                                    key={component.id}
                                    sx={{ mb: 2, backgroundColor: "primary.light", cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleExpand(setExpandedComponent, component.id);
                                    }}
                                  >
                                    <CardContent>
                                      <Typography variant="subtitle1">{component.name}</Typography>

                                      {/* Timeline for Component */}
                                      <Box sx={{ mt: 1 }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                          <Box sx={{ width: "100%", mr: 1 }}>
                                            <LinearProgress
                                              variant="determinate"
                                              value={compPercentage}
                                              sx={{
                                                height: 10,
                                                borderRadius: 5,
                                                "& .MuiLinearProgress-bar": { backgroundColor: compColor },
                                                backgroundColor: "#e0e0e0",
                                              }}
                                            />
                                          </Box>
                                          <Box sx={{ minWidth: 35 }}>
                                            <Typography variant="body2" color="textSecondary">
                                              {`${Math.round(compPercentage)}%`}
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Typography variant="body2" color="textSecondary">
                                          Viimeisin huolto: {component.last_maint || "Ei tietoa"}
                                        </Typography>
                                      </Box>

                                      <Collapse in={expandedComponent[component.id]} timeout="auto" unmountOnExit>
                                        <Box sx={{ ml: 2, mt: 2 }}>
                                          {component.subcomponents.map((subcomponent) => {
                                            const { percentage: subPercentage, barColor: subColor } = calculateTimeline(
                                              subcomponent.last_maint,
                                              subcomponent.maint_interval,
                                              subcomponent.lifespan
                                            );

                                            return (
                                              <Card
                                                key={subcomponent.id}
                                                sx={{ mb: 2, backgroundColor: "secondary.light" }}
                                              >
                                                <CardContent>
                                                  <Typography variant="subtitle2">{subcomponent.name}</Typography>

                                                  {/* Timeline for Subcomponent */}
                                                  <Box sx={{ mt: 1 }}>
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                      <Box sx={{ width: "100%", mr: 1 }}>
                                                        <LinearProgress
                                                          variant="determinate"
                                                          value={subPercentage}
                                                          sx={{
                                                            height: 10,
                                                            borderRadius: 5,
                                                            "& .MuiLinearProgress-bar": { backgroundColor: subColor },
                                                            backgroundColor: "#e0e0e0",
                                                          }}
                                                        />
                                                      </Box>
                                                      <Box sx={{ minWidth: 35 }}>
                                                        <Typography variant="body2" color="textSecondary">
                                                          {`${Math.round(subPercentage)}%`}
                                                        </Typography>
                                                      </Box>
                                                    </Box>
                                                    <Typography variant="body2" color="textSecondary">
                                                      Viimeisin huolto: {subcomponent.last_maint || "Ei tietoa"}
                                                    </Typography>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            );
                                          })}
                                        </Box>
                                      </Collapse>
                                    </CardContent>
                                  </Card>
                                );
                              })}
                            </Box>
                          </Collapse>
                        </CardContent>
                      </Card>
                    );
                  })}
                </Box>
              </Collapse>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};

export default HierarchicalView;
