import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import CustomTable from "../styles/CustomTable";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import {
  Checkbox,
  ListItemText,
  useMediaQuery,
  FormControl,
  InputLabel,
  Paper,
} from "@mui/material";
import theme from "../styles/theme";
import { useParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const backendUrl = process.env.REACT_APP_API_URL;

function MaintenanceHistory() {
  const { asoyId } = useParams();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    performed_on: "",
    cost: "",
    structural_elements: [],
    elements: [],
    components: [],
    subcomponents: [],
  });
  const [editingId, setEditingId] = useState(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [fieldErrors, setFieldErrors] = useState({});
  const [options, setOptions] = useState({
    structural_elements: [],
    elements: [],
    components: [],
    subcomponents: [],
  });

  const fetchOptions = useCallback(async () => {
    try {
      const [structuralRes, elementRes, componentRes, subcomponentRes] =
        await Promise.all([
          axios.get(`${backendUrl}/asoy/${asoyId}/structural_elements`),
          axios.get(`${backendUrl}/asoy/${asoyId}/elements`),
          axios.get(`${backendUrl}/asoy/${asoyId}/components`),
          axios.get(`${backendUrl}/asoy/${asoyId}/subcomponents`),
        ]);
      setOptions({
        structural_elements: structuralRes.data,
        elements: elementRes.data,
        components: componentRes.data,
        subcomponents: subcomponentRes.data,
      });
    } catch (error) {
      console.error("Virhe haettaessa vaihtoehtoja:", error);
    }
  }, [asoyId]);

  const fetchMaintenanceHistory = useCallback(async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/asoy/${asoyId}/maintenance-history`
      );
      setRows(response.data);
    } catch (error) {
      console.error("Virhe haettaessa huoltohistoriaa:", error);
    }
  }, [asoyId]);

  useEffect(() => {
    if (asoyId) {
      fetchOptions();
      fetchMaintenanceHistory();
    }
  }, [asoyId, fetchOptions, fetchMaintenanceHistory]);

  const handleAddOrUpdate = async () => {
    const errors = {};
    if (!formData.name) errors.name = "Pakollinen";
    if (!formData.description) errors.description = "Pakollinen";
    if (!formData.performed_on) errors.performed_on = "Pakollinen";

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    const payload = {
      name: formData.name,
      description: formData.description,
      performed_on: formData.performed_on,
      cost: formData.cost ? parseFloat(formData.cost) : null,
      structural_elements: formData.structural_elements,
      elements: formData.elements,
      components: formData.components,
      subcomponents: formData.subcomponents,
    };

    try {
      if (editingId) {
        await axios.put(
          `${backendUrl}/asoy/${asoyId}/maintenance-history/${editingId}`,
          payload
        );
      } else {
        await axios.post(
          `${backendUrl}/asoy/${asoyId}/maintenance-history`,
          payload
        );
      }
      setOpen(false);
      setEditingId(null);
      setFormData({
        name: "",
        description: "",
        performed_on: "",
        cost: "",
        structural_elements: [],
        elements: [],
        components: [],
        subcomponents: [],
      });
      fetchMaintenanceHistory();
    } catch (error) {
      console.error(
        editingId
          ? "Virhe päivitettäessä huoltohistoriaa:"
          : "Virhe lisättäessä huoltohistoriaa:",
        error
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
    setFieldErrors({});
    setEditingId(null);
    setFormData({
      name: "",
      description: "",
      performed_on: "",
      cost: "",
      structural_elements: [],
      elements: [],
      components: [],
      subcomponents: [],
    });
  };

  const handleRowClick = (row) => {
    setFormData({
      name: row.name || "",
      description: row.description || "",
      performed_on: row.performed_on || "",
      cost: row.cost || "",
      structural_elements: row.structural_elements?.map((obj) => obj.id) || [],
      elements: row.elements?.map((obj) => obj.id) || [],
      components: row.components?.map((obj) => obj.id) || [],
      subcomponents: row.subcomponents?.map((obj) => obj.id) || [],
    });
    setEditingId(row.id);
    setOpen(true);
  };

  const columns = [
    { id: "name", label: "Nimi", align: "left" },
    { id: "description", label: "Kuvaus", align: "left" },
    { id: "performed_on", label: "Suoritettu", align: "left" },
    { id: "cost", label: "Kustannus (€)", align: "right" },
  ];

  const getFieldLabel = (key) => {
    const labels = {
      structural_elements: "Valitse rakenneosat",
      elements: "Valitse elementit",
      components: "Valitse komponentit",
      subcomponents: "Valitse alikomponentit"
    };
    return labels[key] || key;
  };

  const handleKeyDown = (event, nextField) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      const nextElement = document.querySelector(`[name="${nextField}"]`);
      if (nextElement) {
        nextElement.focus();
      }
    }
  };

  const selectFields = [
    "structural_elements",
    "elements",
    "components",
    "subcomponents",
  ];

  return (
    <Box sx={{ display: "flex", marginLeft: isSmallScreen ? "-60px" : "0px" }}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <CustomTable
          columns={columns}
          rows={rows}
          onRowClick={handleRowClick}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            mt: 2,
          }}
        >
          <Button variant="contained" onClick={() => setOpen(true)}>
            Lisää uusi huoltohistoria
          </Button>
        </Box>

        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>
            {editingId ? "Päivitä huoltohistoria" : "Lisää huoltohistoria"}
          </DialogTitle>
          <DialogContent sx={{ '& > *': { mt: 2 } }}>
            <TextField
              label="Nimi"
              fullWidth
              variant="standard"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              error={Boolean(fieldErrors.name)}
              helperText={fieldErrors.name}
              onKeyDown={(e) => handleKeyDown(e, 'description')}
              name="name"
              tabIndex={1}
            />
            <TextField
              label="Kuvaus"
              fullWidth
              variant="standard"
              value={formData.description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              error={Boolean(fieldErrors.description)}
              helperText={fieldErrors.description}
              onKeyDown={(e) => handleKeyDown(e, 'performed_on')}
              name="description"
              tabIndex={2}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Suoritettu"
                value={
                  formData.performed_on ? new Date(formData.performed_on) : null
                }
                onChange={(newDate) => {
                  const dateString = newDate
                    ? newDate.toISOString().slice(0, 10)
                    : "";
                  setFormData((prev) => ({
                    ...prev,
                    performed_on: dateString,
                  }));
                }}
                maxDate={new Date()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="standard"
                    sx={{ minWidth: "100%" }}
                    error={Boolean(fieldErrors.performed_on)}
                    helperText={fieldErrors.performed_on}
                    onKeyDown={(e) => handleKeyDown(e, 'cost')}
                    name="performed_on"
                    tabIndex={3}
                  />
                )}
              />
            </LocalizationProvider>

            <TextField
              label="Kustannus (€)"
              fullWidth
              variant="standard"
              type="number"
              value={formData.cost}
              onChange={(e) =>
                setFormData({ ...formData, cost: e.target.value })
              }
              onKeyDown={(e) => handleKeyDown(e, 'structural_elements')}
              name="cost"
              tabIndex={4}
            />
            
            <Box sx={{ mt: 3 }}>
              {selectFields.map((key, index) => (
                <Paper 
                  elevation={1} 
                  sx={{ 
                    p: 2, 
                    mb: 2,
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    },
                  }}
                  key={key}
                >
                  <FormControl
                    fullWidth
                    variant="standard"
                  >
                    <InputLabel>{getFieldLabel(key)}</InputLabel>
                    <Select
                      multiple
                      value={formData[key]}
                      onChange={(e) =>
                        setFormData({ ...formData, [key]: e.target.value })
                      }
                      renderValue={(selected) =>
                        selected
                          .map(
                            (id) =>
                              options[key].find((opt) => opt.id === id)?.name || ""
                          )
                          .join(", ")
                      }
                      onKeyDown={(e) => {
                        const nextField = selectFields[index + 1];
                        if (nextField) {
                          handleKeyDown(e, nextField);
                        }
                      }}
                      name={key}
                      tabIndex={5 + index}
                    >
                      {options[key].map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          <Checkbox checked={formData[key].includes(option.id)} />
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Paper>
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} tabIndex={9}>Peruuta</Button>
            <Button onClick={handleAddOrUpdate} tabIndex={10}>
              {editingId ? "Päivitä" : "Lisää"}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export default MaintenanceHistory;
