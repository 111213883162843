import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Box,
  CssBaseline,
  Drawer,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  AppBar,
  Typography,
  Collapse,
  List,
} from "@mui/material";
import { ReactComponent as CustomHomeIcon } from "../icons/house.svg";
import { ReactComponent as CustomReportIcon } from "../icons/report.svg";
import { ReactComponent as Logo } from "../icons/kontu.svg";
import { ReactComponent as CustomUserManagementIcon } from "../icons/users.svg";
import { ReactComponent as CustomTeamManagementIcon } from "../icons/team.svg";
import { ReactComponent as CustomBuildingManagerIcon } from "../icons/building-manager.svg";
import { ReactComponent as CustomAsOyManagementIcon } from "../icons/city.svg";
import { ReactComponent as ManagementIcon } from "../icons/management.svg";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useAuth } from "../auth/Authentication";
import AccountMenu from "./AccountMenu";


const drawerWidthCollapsed = 80; // Width when collapsed
const drawerWidthExpanded = 180; // Width when expanded

function SideNavbar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [managementOpen, setManagementOpen] = React.useState(false);
  const { decodedToken } = useAuth();
  const [selectedPage, setSelectedPage] = React.useState(location.pathname.split("/")[1]);
  const [isSidebarHovered, setIsSidebarHovered] = React.useState(false);

  const handleManagementClick = () => {
    setManagementOpen(!managementOpen);
  };

  const handleDrawerMouseEnter = () => {
    setIsSidebarHovered(true);
  };

  const handleDrawerMouseLeave = () => {
    setIsSidebarHovered(false);
  };

  React.useEffect(() => {
    const currentPage = location.pathname.split("/")[1];
    setSelectedPage(currentPage);

    // Check if the current page is one of the management pages
    const managementPages = ["user-management", "building-manager-management", "team-management", "asoy-management"];
    if (managementPages.includes(currentPage)) {
      setManagementOpen(true);
    } else {
      setManagementOpen(false);
    }
  }, [location]);

  const drawerWidth = isSidebarHovered ? drawerWidthExpanded : drawerWidthCollapsed;

  const drawerContent = (
    <div>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "64px",
        }}
      >
        <Logo
          style={{
            height: "48px", // Fixed height to keep logo big
            width: "auto", // Maintain aspect ratio
          }}
        />
      </Toolbar>
      <ListItemButton
        onClick={() => {
          navigate("/");
        }}
        sx={{
          backgroundColor: selectedPage === "" ? "#E1D1C2" : "white",
          justifyContent: "center",
          flexDirection: "column",
          paddingY: 1,
          "&:hover": {
            backgroundColor: "#E1D1C2",
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: "auto",
            justifyContent: "center",
            color: "inherit",
          }}
        >
          <CustomHomeIcon style={{ height: "30px", width: "30px" }} />
        </ListItemIcon>
        {isSidebarHovered && (
          <ListItemText
            primary="Etusivu"
            sx={{
              fontSize: "12px",
              textAlign: "center",
            }}
          />
        )}
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          navigate("/raportit");
        }}
        sx={{
          backgroundColor: selectedPage === "raportit" ? "#E1D1C2" : "white",
          justifyContent: "center",
          flexDirection: "column",
          paddingY: 1,
          "&:hover": {
            backgroundColor: "#E1D1C2",
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: "auto",
            justifyContent: "center",
            color: "inherit",
          }}
        >
          <CustomReportIcon style={{ height: "30px", width: "30px" }} />
        </ListItemIcon>
        {isSidebarHovered && (
          <ListItemText
            primary="Raportit"
            sx={{
              fontSize: "12px",
              textAlign: "center",
            }}
          />
        )}
      </ListItemButton>

      {decodedToken && decodedToken.role === 1 && (
        <div>
          <ListItemButton
            onClick={handleManagementClick}
            sx={{
              justifyContent: "center",
              flexDirection: "column",
              paddingY: 1,
              "&:hover": {
                backgroundColor: "#E1D1C2",
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "auto",
                justifyContent: "center",
                color: "inherit",
              }}
            >
              <ManagementIcon style={{ height: "30px", width: "30px" }} />
            </ListItemIcon>
            {isSidebarHovered && (
              <ListItemText
                primary="Hallinta"
                sx={{
                  fontSize: "12px",
                  textAlign: "center",
                }}
              />
            )}
            {isSidebarHovered && (managementOpen ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
          <Collapse in={managementOpen && isSidebarHovered} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate("/user-management");
                }}
                sx={{
                  pl: 4,
                  backgroundColor: selectedPage === "user-management" ? "#E1D1C2" : "white",
                }}
              >
                <ListItemIcon>
                  <CustomUserManagementIcon style={{ height: "30px", width: "30px" }} />
                </ListItemIcon>
                <ListItemText primary="Käyttäjät" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/building-manager-management");
                }}
                sx={{
                  pl: 4,
                  backgroundColor: selectedPage === "building-manager-management" ? "#E1D1C2" : "white",
                }}
              >
                <ListItemIcon>
                  <CustomBuildingManagerIcon style={{ height: "30px", width: "30px" }} />
                </ListItemIcon>
                <ListItemText primary="Isännöitsijät" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/team-management");
                }}
                sx={{
                  pl: 4,
                  backgroundColor: selectedPage === "team-management" ? "#E1D1C2" : "white",
                }}
              >
                <ListItemIcon>
                  <CustomTeamManagementIcon style={{ height: "30px", width: "30px" }} />
                </ListItemIcon>
                <ListItemText primary="Tiimit" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/asoy-management");
                }}
                sx={{
                  pl: 4,
                  backgroundColor: selectedPage === "asoy-management" ? "#E1D1C2" : "white",
                }}
              >
                <ListItemIcon>
                  <CustomAsOyManagementIcon style={{ height: "30px", width: "30px" }} />
                </ListItemIcon>
                <ListItemText primary="Taloyhtiöt" />
              </ListItemButton>
            </List>
          </Collapse>
        </div>
      )}
    </div>
  );

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          bgcolor: "background.default",
          transition: "width 0.3s, margin 0.3s",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6" noWrap>
            TaloKunto
          </Typography>
          <Box>
            <AccountMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        onMouseEnter={handleDrawerMouseEnter}
        onMouseLeave={handleDrawerMouseLeave}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            transition: "width 0.3s",
            overflowX: "hidden",
          },
        }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
}

export default SideNavbar;
