import React, { useState, useEffect, useCallback } from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import AsoyNav from "../navigation/AsoyNav";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams } from "react-router-dom";
import theme from "../styles/theme";
import MainLayout from "../MainLayout";
import StructuralElements from "../datatables/StructuralElements";
import Maintenances from "../datatables/Maintenances";
import Inspections from "../datatables/MaintenanceHistory";
import ElementsTable from "../datatables/ElementsTable";
import Plans from "../datatables/Plans"; // Import new component
import { useCache } from "../CacheProvider";
import { useMediaQuery } from "@mui/material";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_URL;

function AsoyDetails() {
  const { asoyId } = useParams();
  const [asoyDetails, setAsoyDetails] = useState(null);
  const { get, set } = useCache();

  const matches = useMediaQuery('(min-width:600px)');
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchAsoyDetails = useCallback(async () => {
    try {
      let data = get(`asoy_${asoyId}`);
      if (!data) {
        const response = await axios.get(`${backendUrl}/asoy/${asoyId}/`);
        data = response.data;
        set(`asoy_${asoyId}`, data);
      }

      let basicInfo = get(`basic_asoy_${asoyId}`);
      if (!basicInfo) {
        const basicInfoResponse = await axios.get(`${backendUrl}/basic-asoy/${asoyId}/`);
        basicInfo = basicInfoResponse.data;
        set(`basic_asoy_${asoyId}`, basicInfo);
      }

      data = { ...data, ...basicInfo };
      setAsoyDetails(data);
    } catch (error) {
      console.error("Error fetching asoy details: ", error);
    }
  }, [asoyId, get, set]);

  useEffect(() => {
    fetchAsoyDetails();
  }, [fetchAsoyDetails]);

  const goBack = () => {
    window.history.back();
  };

  return (
    <MainLayout>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <div style={{ minHeight: "100vh" }}>
          <div style={{ flexGrow: 1, padding: 1 }}>
            <Button
              variant="text"
              startIcon={<ArrowBackIcon />}
              sx={{
                color: theme.palette.primary.dark,
                marginBottom: theme.spacing(2),
                '&:hover': {
                  backgroundColor: '#F8F2ED',
                },
              }}
              onClick={goBack}
            >
              Takaisin
            </Button>
            <Card style={{ backgroundColor: '#F8F2ED', borderRadius: '10px' }}>
              <CardContent>
                <Typography
                  component="div"
                  variant={isSmallScreen ? "h6" : "h5"}
                  style={{ paddingLeft: isSmallScreen ? '0%' : '5%', paddingBottom: '20px' }}
                >
                  {asoyDetails ? asoyDetails.name : "Loading asoy details..."}
                </Typography>
                <div style={{
                  display: 'flex',
                  flexDirection: matches ? 'row' : 'column',
                  justifyContent: 'space-between',
                  fontSize: '0.875rem'
                }}>
                  <Typography component="div" variant="body2" style={{ paddingLeft: matches ? '5%' : '0%' }}>
                    {asoyDetails ? `${asoyDetails.address_road} ${asoyDetails.address_number}, ${asoyDetails.address_postal_code} ${asoyDetails.address_city}` : "Loading asoy details..."}
                  </Typography>
                  <Typography component="div" variant="body2">
                    {asoyDetails ? `Y-tunnus: ${asoyDetails.business_id}` : "Loading asoy details..."}
                  </Typography>
                  <Typography component="div" variant="body2" style={{ paddingRight: '5%' }}>
                    {asoyDetails && asoyDetails.building_manager ? `Isännöitsijä: ${asoyDetails.building_manager.name}` : "Loading asoy details..."}
                  </Typography>
                </div>
              </CardContent>
            </Card>
            <AsoyNav
              tabs={[
                { label: "Rakenneosat", panel: <StructuralElements asoyId={asoyId} /> },
                { label: "KPTS", panel: <ElementsTable asoyId={asoyId} /> },
                { label: "Huollot", panel: <Maintenances asoyId={asoyId} /> },
                { label: "Korjaushistoria", panel: <Inspections asoyId={asoyId} /> },
                { label: "Suunnitelmat", panel: <Plans asoyId={asoyId} /> }, // New tab
              ]}
            />
          </div>
        </div>
      </Box>
    </MainLayout>
  );
}

export default AsoyDetails;
