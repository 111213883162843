import React from "react";
import Box from "@mui/material/Box";
import AsoyTable from "../datatables/AsoyTable";
import MainLayout from "../MainLayout";
import AsoyProvider from '../AsoyProvider';

// Reports page that shows the list of Asoys
function Reports() {
  return (
    <MainLayout>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <h1>Taloyhtiöt</h1>
        <AsoyProvider>
          <AsoyTable />
        </AsoyProvider>
      </Box>
    </MainLayout>
  );
}

export default Reports;
