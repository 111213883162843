import React from "react";
import { Box, Typography } from "@mui/material";
import HierarchicalView from "./HierarchicalView";
import { useParams } from "react-router-dom";

const StructuralElements = () => {
  const { asoyId } = useParams();

  return (
    <Box sx={{ mt: 2 }}>
      {/* Header */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
          Rakenteet, sähkölaitteet ja LVI
        </Typography>
      </Box>

      {/* Hierarchical View */}
      <HierarchicalView asoyId={asoyId} />
    </Box>
  );
};

export default StructuralElements;
