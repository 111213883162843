import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import AsoyContext from './AsoyContext';
import { useAuth } from './auth/Authentication';
import { useCache } from './CacheProvider';

const backendUrl = process.env.REACT_APP_API_URL;
const PAGE_SIZE = 50;

const AsoyProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [asoyData, setAsoyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const { token } = useAuth();
  const isAuthenticated = !!token;
  const { get, set, remove } = useCache();

  const fetchData = useCallback(async (searchTerm = "", pageNum = 0, append = false) => {
    if (!isAuthenticated) return;

    try {
      setLoading(true);
      const skip = pageNum * PAGE_SIZE;
      
      const response = await axios.get(`${backendUrl}/asoy-all/`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          search: searchTerm || undefined,
          skip,
          limit: PAGE_SIZE
        }
      });

      const newData = response.data;
      
      if (append) {
        setAsoyData(prev => [...prev, ...newData]);
      } else {
        setAsoyData(newData);
      }

      // If we received fewer items than the page size, we've reached the end
      setHasMore(newData.length === PAGE_SIZE);
      
      // Only cache the first page of results
      if (pageNum === 0 && !searchTerm) {
        set('asoy-all', newData);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  }, [isAuthenticated, token, set]);

  // Initial load
  useEffect(() => {
    const cachedData = get('asoy-all');
    if (cachedData) {
      setAsoyData(cachedData);
    } else {
      fetchData();
    }
  }, [fetchData, get]);

  const refreshData = useCallback(() => {
    remove('asoy-all');
    setPage(0);
    fetchData();
  }, [remove, fetchData]);

  const loadMore = useCallback(() => {
    if (!loading && hasMore) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchData(searchQuery, nextPage, true);
    }
  }, [loading, hasMore, page, searchQuery, fetchData]);

  const debouncedSearch = useMemo(
    () => 
      debounce((term) => {
        setPage(0);
        fetchData(term);
      }, 300),
    [fetchData]
  );

  const handleSearchChange = useCallback((event) => {
    const term = event.target.value.toLowerCase();
    setSearchQuery(term);
    debouncedSearch(term);
  }, [debouncedSearch]);

  const contextValue = useMemo(() => ({
    filteredData: asoyData, // Data is already sorted by the backend
    handleSearchChange,
    refreshData,
    loadMore,
    hasMore,
    loading
  }), [asoyData, handleSearchChange, refreshData, loadMore, hasMore, loading]);

  return (
    <AsoyContext.Provider value={contextValue}>
      {children}
    </AsoyContext.Provider>
  );
};

export default AsoyProvider;
